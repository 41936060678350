import { IFormFields } from "src/modals/contact-forms-form-builder/store"
import {
    IDynamicFieldsListItemProps,
    IExternalDynamicFieldsListItemProps,
    IMetaDataFieldListItemProps,
} from "src/modals/contact-forms-form-builder/types/fieldsListItem"

import { getFieldsArrayRequest } from "src/modals/contact-forms-form-builder/helper/getFieldsArrayRequestHelper"

import { FormFields } from "src/lib/form-fields"

import { OutputType } from "src/types/output"
import {
    contactv2_FormMetadataField,
    contactv2_MetadataFieldSource,
} from "src/api"

export const createUpdateRequestBody = (
    formFields: FormFields<IFormFields>,
    externalDynamicFieldsList: IExternalDynamicFieldsListItemProps[],
    dynamicFieldsList: IDynamicFieldsListItemProps[],
    metaDataFieldsList: IMetaDataFieldListItemProps[],
    integrationId: number,
    isEditMode: boolean,
) => {
    const isIntegrationSelected =
        formFields.get("outputTypeSelectedValue") === OutputType.Integration
    const isExternalFormIdFilled = formFields.get("externalFormId") !== ""
    const isSetFieldsArrayContainsEmptyUuid =
        externalDynamicFieldsList.filter((item) => item.uuid === "").length >
            0 || dynamicFieldsList.filter((item) => item.uuid === "").length > 0
    const getFieldsArray = () => {
        if (isEditMode && isSetFieldsArrayContainsEmptyUuid) {
            return [
                {
                    description: formFields.get("formFieldDescription") ?? "",
                    name: formFields.get("formFieldTitle") ?? "",
                },
            ]
        }
        const fieldsArrayRequest = getFieldsArrayRequest(
            formFields.get("outputTypeSelectedValue") ?? "",
            formFields.get("formFieldDescription") ?? "",
            formFields.get("formFieldTitle") ?? "",
            externalDynamicFieldsList,
            dynamicFieldsList,
        )
        return fieldsArrayRequest
    }

    const getMetaDataFieldsArray = () => {
        return metaDataFieldsList.reduce(
            (acc: contactv2_FormMetadataField[], item) => {
                if (item.source.value.trim() !== "") {
                    acc.push({
                        label: item.label.value,
                        source: item.source
                            .value as contactv2_MetadataFieldSource,
                    })
                }

                return acc
            },
            [],
        )
    }

    const request = {
        access_group_id: formFields.get("accessGroupId") ?? -1,
        description: formFields.get("description") ?? "",
        email: formFields.get("email") ?? [],
        fields: getFieldsArray(),
        icon_url: formFields.get("iconUrl") ?? "",
        name: formFields.get("name") ?? "",
        send_email: formFields.get("sendEmail") ?? false,
        messaging_enabled: formFields.get("messagingEnabled") ?? false,
        use_ai_title: formFields.get("useAiTitle") ?? false,
        metadata_fields: getMetaDataFieldsArray(),
    }
    if (isIntegrationSelected) {
        Object.assign(request, {
            integration_connector_id: integrationId,
        })
    }
    if (isExternalFormIdFilled) {
        Object.assign(request, {
            external_id: formFields.get("externalFormId"),
        })
    }
    return request
}
