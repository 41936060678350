import { observer } from "mobx-react"
import { FormControlLabel, IconButton, Switch } from "@mui/material"

import { t } from "@lingui/macro"

import {
    CardBottomContainer,
    VerticalSeparator,
} from "src/modals/contact-forms-form-builder/styled"

import { IBottomContainerSectionProps } from "src/modals/contact-forms-form-builder/types/bottomContainerSection"

import { FormDetailStore } from "src/modals/contact-forms-form-builder/store"

import { DeleteBinOutlined } from "src/components/icons/DeleteBinOutlined"
import { ContentCopy } from "src/components/icons/ContentCopy"
import { useStore } from "src/store/lib/useStore"

export const BottomContainerSection = observer(
    (props: IBottomContainerSectionProps) => {
        const store = useStore(FormDetailStore)
        return (
            <CardBottomContainer>
                {props.variant === "default" && (
                    <FormControlLabel
                        checked={props.isRequired}
                        onChange={() => props.handleSwitchChange(props.index)}
                        control={<Switch color="success" />}
                        label={t`contact-form-forms-detail-modal.form-field-section.required-label`}
                        sx={{ ml: 1 }}
                    />
                )}
                <VerticalSeparator />
                <IconButton
                    size="small"
                    color="inherit"
                    disabled={store.isEditMode}
                    onClick={() => props.handleContentCopyClick(props.index)}
                >
                    <ContentCopy />
                </IconButton>
                <IconButton
                    size="small"
                    color="inherit"
                    disabled={store.isEditMode || props.disableButton}
                    onClick={() => props.handleDeleteClick(props.index)}
                >
                    <DeleteBinOutlined />
                </IconButton>
            </CardBottomContainer>
        )
    },
)
